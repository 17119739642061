// import
import { Link as RouterLink } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'

// styles
import './Footer.scss';

// images
import logo_white from '../../../assets/images/logos/logo-white.webp'

// nav items
const navItems = [
    {
        label: 'Inicio',
        path: '/'
    },
    {
        label: 'Compra tu Auto',
        path: '/compra-tu-auto'
    },
    {
        label: 'Vende tu Auto',
        path: '/vende-tu-auto'
    },
    {
        label: 'Consigna tu Auto',
        path: '/consigna-tu-auto'
    },
    {
        label: 'Servicios Automotrices',
        path: '/servicios-automotrices'
    },
    // {
    //     label: 'Apartado Digital',
    //     href: '/apartado-digital',
    // },
    {
        label: 'Políticas de Privacidad',
        href: '/politicas-de-privacidad',
    },
]

// social networks
const socialNetworks = [
    'https://www.facebook.com/',
    'https://www.instagram.com/',
    'https://www.twitter.com/',
    'https://www.youtube.com/',
]

// contact data
const insuranceNumber = '+52 66 9383 0574'
const insuranceWhatsapp = `https://wa.me/${insuranceNumber.replace('+', '').replaceAll(' ', '')}?text=${encodeURIComponent('Me interesa contratar un seguro')}`

const salesNumber = '+52 66 9383 0568'
const salesWhatsapp = `https://wa.me/${salesNumber.replace('+', '').replaceAll(' ', '')}?text=${encodeURIComponent('Hola, me interesa contactar con ustedes')}`

const infoNumber = '+52 66 9383 0570'
const infoWhatsapp = `https://wa.me/${infoNumber.replace('+', '').replaceAll(' ', '')}?text=${encodeURIComponent('Hola, deseo conocer más información.')}`

const questionsNumber = '+52 66 9383 0572'
const questionsWhatsapp = `https://wa.me/${questionsNumber.replace('+', '').replaceAll(' ', '')}?text=${encodeURIComponent('Hola, tengo una pregunta para ustedes.')}`

const customerEmail = 'atencion@nowy.mx'
const infoEmail = 'info@nowy.mx'
const serviceEmail = 'servicios@nowy.mx'

// component
function Footer() {
    const currentYear = new Date().getFullYear();

    // render functions
    const renderNavItems = () => {
        const items = []

        for (let index in navItems) {
            let item = navItems[index]

            if (item.href) {
                items.push(
                    <a
                        key={`footer_navitem_${index}`}
                        href={item.href}
                        className="mb-2 text-xs"
                    >
                        {item.label}
                    </a>
                )
            }
            else if (item.path) {
                items.push(
                    <RouterLink
                        key={`footer_navitem_${index}`}
                        to={item.path}
                        className="mb-2 text-xs"
                    >
                        {item.label}
                    </RouterLink>
                )
            }
        }

        return items
    }

    // render component
    return <>
        <div id="footer">
            <div className="bg-gray-800	text-white">
                <div className="container mx-auto px-10 py-8">
                    <div className="flex flex-row justify-start grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                        <div className="px-4 py-2 text-xs">
                            <div className='flex justify-center'>
                                <img
                                    src={logo_white}
                                    alt='logo'
                                    style={{
                                    width:'180px',
                                    height:"57px"}}
                                />
                            </div>

                            <p className="py-2 mt-2 text-slate-400 mb-2">
                                1 Mazatlan (Carretera Internacional al Norte KM 1201, 82120, Sin.)
                            </p>

                            <hr className="mb-2" />

                            <div className="flex flex-col">
                                <p className="text-slate-400">
                                    Contrata un Seguro Automotriz
                                    <br />
                                    Para conocer más información, contactanos
                                </p>

                                <p className="py-2 mt-2">
                                    WhatsApp
                                    <br />
                                    <a
                                        className="text-slate-400"
                                        href={insuranceWhatsapp}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {insuranceNumber}
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div className="px-4 py-2">
                            <div className="flex flex-col text-xs">
                                {renderNavItems()}
                            </div>
                        </div>

                        <div className="px-4 py-2 text-xs">
                            <div className="flex flex-col">
                                <p className="text-slate-400">
                                    ¿Tienes alguna duda?
                                    <br />
                                    Contacta con nuestro servicio al cliente
                                </p>

                                <p className="py-2 mt-2">
                                    Atención de Compra, Venta y Consignaciones
                                    <br />
                                    <a
                                        className="text-slate-400"
                                        href={salesWhatsapp}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {salesNumber}
                                    </a>
                                </p>

                                <p className="py-2 mt-2">
                                    Atención e Información
                                    <br />
                                    <a
                                        className="text-slate-400"
                                        href={infoWhatsapp}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {infoNumber}
                                    </a>
                                </p>

                                <p className="py-2 mt-2">
                                    Dudas y Comentarios
                                    <br />
                                    <a
                                        className="text-slate-400"
                                        href={questionsWhatsapp}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {questionsNumber}
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div className="px-4 py-2 text-xs">
                            <div className="flex flex-col">
                                <p className="text-slate-400">
                                    También puedes contactar con nosotros vía correo electrónico
                                </p>

                                <p className="py-2 mt-2">
                                    Atención a clientes
                                    <br />
                                    <a
                                        className="text-slate-400"
                                        href={`mailto:${customerEmail}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {customerEmail}
                                    </a>
                                </p>

                                <p className="py-2 mt-2">
                                    Información
                                    <br />
                                    <a
                                        className="text-slate-400"
                                        href={`mailto:${infoEmail}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {infoEmail}
                                    </a>
                                </p>

                                <p className="py-2 mt-2">
                                    Satisfacción y Servicio
                                    <br />
                                    <a
                                        className="text-slate-400"
                                        href={`mailto:${serviceEmail}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {serviceEmail}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row justify-center pb-4">
                    {socialNetworks.map((network, index) => (
                        <div
                            key={`network_${index}`}
                            className="px-1"
                        >
                            <SocialIcon
                                url={network}
                                fgColor="#fff"
                                style={{
                                    height: 30,
                                    width: 30,
                                }}
                                target="_blank"
                            />
                        </div>
                    ))}
                </div>

                <div className="text-center mt-2 bg-gray-900 py-2 text-xs">
                    <p>
                        Copyright © {currentYear} Nowy. Todos los derechos reservados.
                    </p>
                </div>
            </div>
        </div>
    </>;
}

export default Footer;