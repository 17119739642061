// hooks
import {
    useState,
    useEffect,
} from 'react'
import testData from './SellCar.test'

// components
import SellCarHeader from './components/SellCarHeader'
import SellCarTestimonials from './components/SellCarTestimonials'
import SellCarForm from './components/SellCarForm'

// styles
import './SellCar.scss'
import { Seo } from '../shared/Seo/Seo'
import { useLocation, useNavigate } from 'react-router-dom'

// component
function SellCar({type}) {
    // state
    const [showForm, setShowForm] = useState(testData.enable && testData.showForm)
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(()=>{
        const path = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
        if(showForm === false && path !== 'vende-tu-auto'){
            navigate(`/vende-tu-auto`)
        }
        if(showForm === true && path === 'vende-tu-auto'){
            navigate(`/vende-tu-auto/anio`)
        }
    }, [showForm, location.pathname, navigate])
    // init render
    useEffect(() => {
        // scroll to top
        window.scrollTo(0, 0)
    }, [])
    
    // render component
    return <>
        <section id="sell-car">
        <Seo
            title="Nowy | Venta de autos usados y seminuevos"
            description="¿Buscar vender tu auto? Ofrecemos el mejor servicio para la  venta de autos usados y seminuevos en Mazatlán, Culiacán, Tepic, Vallarta, La Paz, San José del Cabo y Cabo San Lucas. Vende tu auto de manera rápida y segura con nosotros."
            companyName="Nowy"
            type="website"
        />
            <SellCarHeader
                setShowForm={setShowForm}
            />
            <SellCarTestimonials
                setShowForm={setShowForm}
            />
            <SellCarForm 
                showForm={showForm}
                setShowForm={setShowForm}
                type={type}
            />
        </section>
    </>
}

export default SellCar
