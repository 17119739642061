import { CheckCircleOutline } from '@mui/icons-material'
import { Button, Container, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const SuccessPayment = () => {
    const navigate = useNavigate();
  return (
    <Container maxWidth="xl" sx={{height: '70dvh',alignContent: 'center'}}>
        <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Grid item sx={{margin: 'auto'}}>
                <CheckCircleOutline sx={{fontSize: 250, color:'#00ce7c'}}/>
            </Grid>
            <Grid item sx={{margin: 'auto'}}>
                <Typography variant='h2' sx={{textAlign: 'center', color: '#00ce7c'}}>Pago exitoso</Typography>
                <Typography sx={{fontSize: '24px', textAlign: 'center'}}>Tu pago se ha completado correctamente</Typography>
            </Grid>
            <Grid item sx={{margin: '20px auto auto auto'}}>
                <Button onClick={() => navigate('/')} variant='contained' sx={{backgroundColor: '#212529'}}> Volver al inicio </Button>
            </Grid>
            
        </Grid>
      </Container>
  )
}
