// components
import {
    Box,
    Grid,
    Button,
} from '@mui/material'

// slider images
import backgroundImg from '../../../assets/images/home/home-tu-sueño_banner_agenda-cita_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import { Videocam } from '@mui/icons-material'
import LazyLoad from 'react-lazyload'
import { PreloadImages } from '../../shared/PreloadImages/PreloadImages'

const zoomUrl = 'https://us06web.zoom.us/j/84291532904?pwd=VGtyaytkZWZZS3hNWklDakZqWmwwdz09'
const faqsUrl = 'https://media-autos-qa.s3.us-west-1.amazonaws.com/PDF_media/PREGUNTAS+FRECUENTES_NOWY.pdf'

// component
function HomeBanner() {
    return <>
    <PreloadImages images={[backgroundImg]}/>
    <LazyLoad>
        <Box className="relative container-fluid bg-cover bg-center"
            sx={{
                backgroundColor: '#00CE7C',
                backgroundImage: `url(${backgroundImg})`
            }}
        >
            <Box className="absolute w-full h-full z-0"
                sx={{
                    backgroundColor: 'rgba(0,0,0,0.75)',
                }}
            />
            
            <Box className="relative container p-[20px] z-10 py-[80px] min-h-[450px]">
                <Grid container
                    spacing={2}
                    alignItems="center"
                >
                    <Grid item
                        xs={12}
                        md={8}
                        lg={6}
                    >
                        <h2 className='font-title text-3xl lg:text-6xl md:text-left text-center text-white py-[20px]'>
                            Tu sueño es importante
                        </h2>

                        <Box className='place-items-center'
                            sx={{
                                display: 'flex',
                                flexDirection:{
                                    xs: 'column',
                                    md: 'row',
                                },
                                gap: 3
                            }}
                        >
                            <Button onClick={() => {
                                window.open(zoomUrl)
                            }}
                            sx={{
                                backgroundColor:'#00CE7C',
                                textAlign: 'center',
                                color: 'black',
                                fontFamily: 'Poppins-Bold',
                                padding: '10px',
                                paddingX: '20px',
                                borderRadius: 10,
                            }}
                            >
                                <Videocam />
                                Agenda cita
                            </Button>

                            <Button onClick={() => {
                                    window.open(faqsUrl)
                                }}
                                sx={{
                                    color: 'black',
                                    backgroundColor:'#00CE7C',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins-Bold',
                                    padding: '10px',
                                    paddingX: '20px',
                                    borderRadius: 10
                                }}
                            >
                                Preguntas frecuentes
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        </LazyLoad>
    </>
}

// export
export default HomeBanner
