// import
import {
    Box,
    Modal,
    Button,
    List,
    ListItem,
    ListItemButton,
    CircularProgress,
    Input,
    InputAdornment,
    FormHelperText,
    Select,
    MenuItem,
} from '@mui/material'
import validator from 'validator'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import { 
    LocalizationProvider,
    DatePicker,
} from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { SocialIcon } from 'react-social-icons'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

// hooks
import {
    useState,
    useEffect,
} from 'react'

// icons
import {
    AccessTime,
    Close,
    KeyboardBackspace,
    Search,
    DirectionsCar,
    CheckCircle,
    EventNote,
    LocationOn,
    AlternateEmail,
    LocalPhone,
} from '@mui/icons-material'

// images
import sellButton from '../../../assets/images/sellcar/vende-tu-auto_cta_190x40-svg_autos-seminuevos_venta-de-autos_auto_seminuevo.svg'
import logo from '../../../assets/images/sellcar/vende-tu-auto_logo-nowy_150webp_autos-seminuevos_venta-de-autos_auto_seminuevo.webp'
import coupe from '../../../assets/images/type-vehicles/home-modelo_coupe_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import hatchback from '../../../assets/images/type-vehicles/home-modelo_hatchback_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import jeep from '../../../assets/images/type-vehicles/home-modelo_jeep_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import pickup from '../../../assets/images/type-vehicles/home-modelo_pickup_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import roadster from '../../../assets/images/type-vehicles/home-modelo_roadster_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import sedan from '../../../assets/images/type-vehicles/home-modelo_sedan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import suv from '../../../assets/images/type-vehicles/home-modelo_suv_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import zoom from '../../../assets/images/zoom/zoom.png'

// services
import valuaService from '../../../services/valuaService'
import sellCarService from '../../../services/sellCarService'

// private functions

const sortCatalog = (catalog, key = 'name') => {
    return catalog.sort((a, b) => {
        const nameA = a[key]?.toUpperCase() || a[key]
        const nameB = b[key]?.toUpperCase() || b[key]
        if (nameA < nameB) {
            return -1
        }

        if (nameA > nameB) {
            return 1
        }

        return 0
    })
}

const numberFormat = (number) => number.toLocaleString('es-MX')

const calculateValuation = (value) => {
    let newPrice = Number(value.replace(/,/g, '').trim()) * 0.90
    newPrice = Number(newPrice.toFixed(2))
    return numberFormat(newPrice)
}

// private const
const images = {
    sellButton: {
        image: sellButton,
        alt: 'vende-tu-auto_cta_190x40-svg_autos-seminuevos_venta-de-autos_auto_seminuevo',
    },
    logo: {
        image: logo,
        alt: 'vende-tu-auto_logo-nowy_150webp_autos-seminuevos_venta-de-autos_auto_seminuevo',
    },
    coupe: {
        image: coupe,
        alt: 'home-modelo_coupe_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    hatchback: {
        image: hatchback,
        alt: 'home-modelo_hatchback_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    jeep: {
        image: jeep,
        alt: 'home-modelo_jeep_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    pickup: {
        image: pickup,
        alt: 'home-modelo_pickup_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    roadster: {
        image: roadster,
        alt: 'home-modelo_roadster_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    sedan: {
        image: sedan,
        alt: 'home-modelo_sedan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    suv: {
        image: suv,
        alt: 'home-modelo_suv_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
}

const steps = [
    'year',
    'brand',
    'model',
    'version',
    'color',
    'kms',
    'contact',
    'quotation',
    'appointment',
    'detail',
]

const contactNumber = '+52 66 9383 0570'
const zoomUrl = 'https://us06web.zoom.us/j/84291532904?pwd=VGtyaytkZWZZS3hNWklDakZqWmwwdz09'

const styles = {
    listButtonSelected: {
        '&.Mui-selected': {
            backgroundColor: '#00ce7c',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(0, 206, 124, 0.2)',
        },
        ':hover': {
            backgroundColor: 'rgba(0, 206, 124, 0.2)',
        },
    },
}

// component
function SellCarForm({
    showForm,
    setShowForm,
}) {
    // state
    const navigate = useNavigate();

    const [step, setStep] = useState('year')
    const [loading, setLoading] = useState(null)
    
    const [years, setYears] = useState([])
    const [year, setYear] = useState(null)

    const [brands, setBrands] = useState([])
    const [searchBrand, setSearchBrand] = useState('')
    const [brand, setBrand] = useState(null)

    const [models, setModels] = useState([])
    const [searchModel, setSearchModel] = useState('')
    const [model, setModel] = useState(null)

    const [versions, setVersions] = useState([])
    const [version, setVersion] = useState(null)

    const [colors, setColors] = useState([])
    const [color, setColor] = useState(null)

    const [kms, setKms] = useState('')
    const [kmsError, setKmsError] = useState('')
    
    const [cellphone, setCellphone] = useState('')
    const [cellphoneError, setCellphoneError] = useState('')
    
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')

    const [price, setPrice] = useState(0)

    const [states, setStates] = useState([])
    const [state, setState] = useState(null)
    const [stateLabel, setStateLabel] = useState('')
    const [stateError, setStateError] = useState('')

    const [cities, setCities] = useState([])
    const [city, setCity] = useState(null)
    const [cityLabel, setCityLabel] = useState('')
    const [cityError, setCityError] = useState('')

    const [postalCode, setPostalCode] = useState('')
    const [postalCodeError, setPostalCodeError] = useState('')

    const [address, setAddress] = useState('')
    const [addressError, setAddressError] = useState('')

    const [date, setDate] = useState(moment())
    const [dateError, setDateError] = useState('')

    const hoursArray = [
        '10:00 a.m.',
        '11:00 a.m.',
        '12:00 p.m.',
        '13:00 p.m.',
        '14:00 p.m.',
        '15:00 p.m.',
        '16:00 p.m.',
        '17:00 p.m.',
    ]
    const [hour, setHour] = useState('')
    const [hourError, setHourError] = useState('')
    
    // init component
    useEffect(() => {
        if(showForm) {
            // reset state
            setYears([])
            setYear(0)
            
            setBrands([])
            setSearchBrand('')
            setBrand(null)
            
            setModels([])
            setSearchModel('')
            setModel(null)
            
            setVersions([])
            setVersion(null)
            
            setColors([])
            setColor(null)
            
            setKms('')
            setKmsError('')
            
            setCellphone('')
            setCellphone('')

            setEmail('')
            setEmailError('')

            setPrice('')

            setState(null)
            setStates([])
            setStateLabel('')
            setStateError('')

            setCity(null)
            setCities([])
            setCityLabel('')
            setCityError('')

            setPostalCode('')
            setPostalCodeError('')

            setAddress('')
            setAddressError('')

            setDate(moment())
            setDateError('')

            setHour('')
            setHourError('')
            
            // reset step
            setStep('year')

            setLoading('Espere un momento mientras actualizamos el listado de años disponibles')

            // request years
            const yearsArray = []
            valuaService.years().then(yearsList => {
                for (const year of yearsList) {
                    yearsArray.push(year.name)
                }
                setYears(yearsArray)
                setLoading(null)
            })

            return
        }
    }, [showForm])

    useEffect(() => {
        switch(step) {
            case 'year':
                setLoading('Espere un momento mientras actualizamos el listado de años disponibles')

                // request years
                valuaService.years().then(yearsList => {
                    if (yearsList) {
                        const yearsArray = []
                        for (const year of yearsList) {
                            yearsArray.push(year.name)
                        }
                        setYears(yearsArray)
                        setLoading(null)
                        return
                    }

                    setYears([])
                    setLoading(null)
                })
            break
            
            case 'brand':
                setLoading('Espere un momento mientras actualizamos el listado de marcas disponibles')

                // request brands
                valuaService.brands(year).then(brandsArray => {
                    if (brandsArray) {
                        const sortedBrands = sortCatalog(brandsArray)
                        setBrands(sortedBrands)
                        setLoading(null)
                        return
                    }
                    
                    setBrands([])
                    setLoading(null)
                })
            break
            
            case 'model':
                setLoading('Espere un momento mientras actualizamos el listado de modelos disponibles')

                // request models
                valuaService.models(year, brand.id).then(modelsArray => {
                    if (modelsArray) {
                        const sortedModels = sortCatalog(modelsArray)
                        setModels(sortedModels)
                        setLoading(null)
                        return
                    }
                    
                    setModels([])
                    setLoading(null)
                })
            break
            
            case 'version':
                setLoading('Espere un momento mientras actualizamos el listado de versiones disponibles')

                // request versions
                valuaService.version(year, brand.id, model.id).then(versionsArray => {
                    if (versionsArray) {
                        const sortedVersions = sortCatalog(versionsArray, 'Nombre')
                        setVersions(sortedVersions)
                        setLoading(null)
                        return
                    }
                    
                    setVersions([])
                    setLoading(null)
                })
            break

            case 'color':
                setLoading('Espere un momento mientras actualizamos el listado de colores disponibles')

                // request colors
                valuaService.color().then(colorsArray => {
                    if (colorsArray) {
                        const sortedColors = sortCatalog(colorsArray, 'Nombre')
                        setColors(sortedColors)
                        setLoading(null)
                        return
                    }
                    
                    setColors([])
                    setLoading(null)
                })
            break

            case 'quotation':
                setLoading('Espere un momento mientra realizamos una oferta previa')

                valuaService.price(
                    year,
                    brand.id,
                    model.id,
                    version.Clave,
                    kms,
                    color.name,
                ).then(priceCotization => {
                    if (priceCotization) {
                        setPrice(priceCotization)
                        setLoading(null)
                    }
                })
            break
            
            case 'appointment':
                setLoading('Espere un momento mientras preparamos el formulario para su cita')

                // request states
                valuaService.states().then(statesArray => {
                    if (statesArray) {
                        const sortedStates = sortCatalog(statesArray, 'state')
                        setStates(sortedStates)
                        setLoading(null)
                        return
                    }
                    
                    setStates([])
                    setLoading(null)
                })
            break
            
            case 'detail':
                setLoading('Espere un momento mientras procesamos los datos ingresados y preparamos su cita...')
                
                const sellData = {
                    brand_id: brand.id,
                    model_id: model.id,
                    version: version.Clave,
                    km: kms,
                    year,
                    state: state.state,
                    city: city.municipalite,
                    postal_code: postalCode,
                    address,
                    date: date.format('YYYY-MM-DD'),
                    hour,
                    phone: cellphone,
                    email,
                }


                sellCarService.send(sellData).then(sellResponse => {
                    if (sellResponse) {
                        setLoading(null)
                    }
                })
            break
            
            default: {}
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    useEffect(() => {
        if (state !== null) {
            // request states
            valuaService.cities(state.id).then(citiesArray => {
                if (citiesArray) {
                    const sortedCities = sortCatalog(citiesArray, 'municipalite')
                    setCities(sortedCities)
                    setLoading(null)
                    return
                }

                setCity(null)
                setCityLabel('')
                setCities([])
                setLoading(null)
            })
        }
    }, [state])

    // form functions
    const backStep = () => {
        const stepIndex = Number(steps.indexOf(step))

        if (stepIndex === 0) {
            setShowForm(false)
            return
        }
        navigate(-1);
        setStep(steps[stepIndex - 1])
    }

    const filterBrands = () => {
        if (searchBrand.trim() === '') {
            return brands
        }

        const brandsFilter = []
        for (const brndItem of brands) {
            if (brndItem.name.toLowerCase().includes(searchBrand.toLowerCase())) {
                brandsFilter.push(brndItem)
            }
        }

        return brandsFilter
    }

    const filterModels = () => {
        if (searchModel.trim() === '') {
            return models
        }

        const modelsFilter = []
        for (const modelItem of models) {
            if (modelItem.name.toLowerCase().includes(searchModel.toLowerCase())) {
                modelsFilter.push(modelItem)
            }
        }

        return modelsFilter
    }

    const evaluateKms = () => {
        if (kms.trim() === '')  {
            setKmsError('Ingrese los kilometros de su vehículo')
            return
        }
        else if(Number(kms) <= 0) {
            setKmsError('Ingrese un kilometraje válido')
            return
        }

        navigate('contacto')
        setStep('contact')
    }

    const evaluateContact = async () => {
        let error = false

        if (cellphone.trim() === '') {
            setCellphoneError('Ingrese su teléfono para continuar')
            error = true
        }

        if (email.trim() === '') {
            setEmailError('Ingrese su email para continuar')
            error = true
        }
        else if (!validator.isEmail(email.trim())) {
            setEmailError('Introduzca un correo válido para continuar')
            error = true
        }

        if (!error) {
            navigate('valuacion')
            setStep('quotation')
        }
    }

    const evaluateAppointment = async () => {
        let error = false

        if (state === null) {
            setStateError('Seleccione un estado de la Republica')
            error = true
        }
        
        if (city === null) {
            setCityError('Seleccione la ciudad de su ubicación')
            error = true
        }

        if (postalCode.trim() === '' || postalCode.trim() <= 0) {
            setPostalCodeError('Ingrese el código postal de su dirección')
            error = true
        }

        if (address.trim() === '') {
            setAddressError('Ingrese su dirección')
            error = true
        }
        
        if (!date.isValid()) {
            setDateError('Ingrese una fecha válida para agendar su cita')
            error = true
        }

        if (hour.trim() === '') {
            setHourError('Seleccione un horario para agendar su cita')
            error = true
        }

        if (!error) {
            navigate(`detalles`)
            setStep('detail')
        }
    }

    const buildWhatsappData = () => {
        let contact_number = contactNumber.replace('+', '').replaceAll(' ', '')

        let message = `Hola, me interesa vender mi auto.\n\n`
        message += 'Mi información es la siguiente...\n'
        message += `Marca: ${brand.name}\n`
        message += `Modelo: ${model.name}\n`
        message += `Año: ${year}\n`
        message += `Versión: ${version.Clave}\n`
        message += `Kilometraje: ${kms}\n`
        message += `Teléfono: ${cellphone}\n`
        message += `Email: ${email}\n`
        message += `Dirección: ${state.state}, ${city.municipalite}, ${address}`
        message = encodeURIComponent(message)
        
        return `https://wa.me/${contact_number}?text=${message}`
    }

    // render functions
    const stepHeader = (back = true, close = true, logo = false) => {
        return(<>
            <Box className='p-[10px] flex items-center mb-[40px]'>
                <Box className={`${logo ? 'w-[20%]' : 'w-[50%]'} flex justify-start`}>
                    { back ? <Button onClick={backStep}>
                        <KeyboardBackspace sx={{
                            fontSize: 30,
                            color: '#00ce7c',
                        }} />
                    </Button> : null }
                </Box>
                
                { logo ? <Box className='w-[60%] flex justify-center'>
                    <img src={images.logo.image} alt={images.logo.alt} />
                </Box> : null }
                
                <Box className={`${logo ? 'w-[20%]' : 'w-[50%]'} flex justify-end`}>
                    { close ? <Button onClick={() => setShowForm(false)}>
                        <Close sx={{
                            fontSize: 30,
                            color: 'black',
                        }} />
                    </Button> : null }
                </Box>
            </Box>
        </>)
    }

    // const renderLoading = () => {
    //     if (loading === null) {
    //         return null
    //     }

    //     return(<>
    //         {stepHeader(false, true)}
            
    //         <Box className='flex flex-col justify-center items-center h-full mt-[-80px]'>
    //             <CircularProgress sx={{
    //                 '&.MuiCircularProgress-colorPrimary': {
    //                     color: '#00ce7c',
    //                 },
    //             }} />

    //             <p className='font-text text-lg text-center my-[20px] max-w-[500px]'>
    //                 {loading}
    //             </p>
    //         </Box>
    //     </>)
    // }

    const renderYears = () => {
        if (step !== 'year' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader()}

            <Box className='mb-[10px]'>
                <h4 className='font-title text-lg'>
                    Seleccione el año de su vehículo
                </h4>
            </Box>

            <Box className='bg-[#00ce7c] p-[10px] mb-[10px] rounded-full flex justify-center items-center'>
                <AccessTime sx={{
                    fontSize: 30,
                    color: 'white',
                }} />

                <p className='text-white font-text text-sm mx-[8px]'>
                    Estás a pocos clicks de descubrir que Nowy tiene la mejor oferta
                </p>
            </Box>

            <Box className='mb-[10px] max-h-[400px] overflow-y-auto'>
                <List>
                    {years.map((yearItem, index) => (
                        <ListItem key={`list_year_item_${index}`}
                            className='border-b border-slate-300'
                        >
                            <ListItemButton selected={yearItem === year}
                                sx={styles.listButtonSelected}
                                onClick={() => {
                                    setYear(yearItem)
                                    navigate('marca')
                                    setStep('brand')
                                }}
                            >
                                <p className='font-text'>
                                    {yearItem}
                                </p>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>)
    }

    const renderBrands = () => {
        if (step !== 'brand' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader()}

            <Box className='mb-[10px]'>
                <h4 className='font-title text-lg'>
                    Seleccione la marca de su vehículo
                </h4>
            </Box>

            <Box className='mb-[10px] form-input'>
                <Input
                    id="search-brand"
                    fullWidth
                    disableUnderline
                    className="px-4 py-2 rounded-full border bg-[#00ce7c]"
                    value={searchBrand}
                    placeholder="Busca por marca"
                    type="text"
                    startAdornment={
                        <InputAdornment position="start">
                            <Search sx={{
                                fontSize: 30,
                                color: 'white',
                            }} />
                        </InputAdornment>
                    }
                    onChange={(event) => {
                        setSearchBrand(event.target.value)
                    }}
                />
            </Box>
            
            <Box className='mb-[10px] max-h-[400px] overflow-y-auto'>
                <List>
                    {filterBrands().map((brandItem, index) => (
                        <ListItem key={`list_year_item_${index}`}
                            className='border-b border-slate-300'
                        >
                            <ListItemButton selected={brandItem.name === brand?.name}
                                sx={styles.listButtonSelected}
                                onClick={() => {
                                    setBrand(brandItem)
                                    navigate('modelo')
                                    setStep('model')
                                }}
                            >
                                <DirectionsCar sx={{
                                    fontSize: 30,
                                }} />

                                <p className='font-text ml-[10px]'>
                                    {brandItem.name}
                                </p>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>)
    }

    const renderModels = () => {
        if (step !== 'model' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader()}

            <Box className='mb-[10px]'>
                <h4 className='font-title text-lg'>
                    Seleccione el modelo
                </h4>
            </Box>

            <Box className='mb-[10px] form-input'>
                <Input
                    id="search-model"
                    fullWidth
                    disableUnderline
                    className="px-4 py-2 rounded-full border bg-[#00ce7c]"
                    value={searchModel}
                    placeholder="Busca por modelo"
                    type="text"
                    startAdornment={
                        <InputAdornment position="start">
                            <Search sx={{
                                fontSize: 30,
                                color: 'white',
                            }} />
                        </InputAdornment>
                    }
                    onChange={(event) => {
                        setSearchModel(event.target.value)
                    }}
                />
            </Box>
            
            <Box className='mb-[10px] max-h-[400px] overflow-y-auto'>
                <List>
                    {filterModels().map((modelItem, index) => (
                        <ListItem key={`list_year_item_${index}`}
                            className='border-b border-slate-300'
                        >
                            <ListItemButton selected={modelItem.name === model?.name}
                                sx={styles.listButtonSelected}
                                onClick={() => {
                                    setModel(modelItem)
                                    navigate('version')
                                    setStep('version')
                                }}
                            >
                                <p className='font-text'>
                                    {modelItem.name}
                                </p>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>)
    }

    const renderVersions = () => {
        if (step !== 'version' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader()}

            <Box className='mb-[10px]'>
                <h4 className='font-title text-lg'>
                    Seleccione la versión
                </h4>
            </Box>

            <Box className='mb-[10px] max-h-[400px] overflow-y-auto'>
                <List>
                    {versions.map((versionItem, index) => (
                        <ListItem key={`list_year_item_${index}`}
                            className='border-b border-slate-300'
                        >
                            <ListItemButton selected={versionItem.Nombre === version?.Nombre}
                                sx={styles.listButtonSelected}
                                onClick={() => {
                                    setVersion(versionItem)
                                    navigate('color')
                                    setStep('color')
                                }}
                            >
                                <p className='font-text'>
                                    {versionItem.Nombre}
                                </p>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>)
    }
    
    const renderColors = () => {
        if (step !== 'color' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader()}

            <Box className='mb-[10px]'>
                <h4 className='font-title text-lg'>
                    Seleccione el color
                </h4>
            </Box>

            <Box className='mb-[10px] max-h-[400px] overflow-y-auto'>
                <List>
                    {colors.map((colorItem, index) => (
                        <ListItem key={`list_year_item_${index}`}
                            className='border-b border-slate-300'
                        >
                            <ListItemButton selected={colorItem.name === color?.name}
                                sx={styles.listButtonSelected}
                                onClick={() => {
                                    setColor(colorItem)
                                    navigate('kms')
                                    setStep('kms')
                                }}
                            >
                                <Box 
                                    className={`rounded-full w-[30px] h-[30px]`}
                                    sx={{
                                        backgroundColor: colorItem.color,
                                        borderWidth: 1,
                                        borderColor: '#E0E0E0',
                                    }}
                                />
                                
                                <p className='font-text mx-[10px]'>
                                    {colorItem.name}
                                </p>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>)
    }

    const renderKms = () => {
        if (step !== 'kms' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader()}

            <Box className='mb-[10px]'>
                <h4 className='font-title text-lg'>
                    Kilometraje aproximado
                </h4>

                <Box className="p-2">
                    <Input
                        fullWidth
                        disableUnderline
                        className="px-4 py-2 rounded-full border border-[#00ce7c]"
                        value={kms}
                        placeholder="Ingresa aquí el kilometraje"
                        type="number"
                        inputProps={{
                            min: 0,
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'e' || event.key === '-' || event.key === '+') {
                                event.preventDefault()
                            }
                        }}
                        onChange={(event) => {
                            let value = event.target.value
                            let numberKms = Math.abs(parseFloat(value))
                            
                            if (Number.isNaN(numberKms)) {
                                value = ''
                            }

                            setKms(value)
                            setKmsError('')
                        }}
                    />
                    <FormHelperText className="text-sm font-text !text-error">{kmsError}</FormHelperText>
                </Box>
                
                <Box className="p-2 flex justify-center">
                        <Button
                            variant="contained"
                            className="w-48 rounded-full"
                            onClick={() => {
                                evaluateKms()
                            }}
                            sx={{
                                "border-radius": '20px',
                                backgroundColor: "#63C088",
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: '#000',
                                },
                            }}
                        >
                            Siguiente
                        </Button>


                    
                </Box>
            </Box>
        </>)
    }

    const renderContact = () => {
        if (step !== 'contact' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader(true, true, true)}

            <Box className='grid place-items-center'>
                <p className='font-title text-2xl text-brandPrimary mt-[10px]'>
                    Avalúo Digital
                </p>

                <Box className='mt-[10px]'>
                    <CircularProgress sx={{
                        '&.MuiCircularProgress-colorPrimary': {
                            color: '#00ce7c',
                        },
                    }} />
                </Box>
                
                <Box className='my-[10px]'>
                    <img src={images.coupe.image} alt={images.coupe.alt} width='250px' />
                </Box>
            </Box>

            <Box>
                <p className='font-title text-2xl text-center mb-[10px]'>
                    Para conocer la oferta previa ingrese datos
                </p>

                <p className='font-title text-lg text-brandPrimary text-center'>
                    Recibirá un código de verificación en celular
                </p>
                
                <Box className="p-2 phoneInput">
                    <label className='font-text text-md'>
                        Celular
                    </label>

                    <PhoneInput
                        defaultCountry='mx'
                        value={cellphone}
                        onChange={(phone) => {
                            setCellphone(phone)
                            setCellphoneError('')
                        }}
                    />
                    <FormHelperText className="text-sm font-text !text-error">{cellphoneError}</FormHelperText>
                </Box>

                <Box className="p-2 mb-[10px]">
                    <label className='font-text text-md'>
                        Correo
                    </label>
                    
                    <Input
                        fullWidth
                        disableUnderline
                        className="px-4 py-2 rounded-full border"
                        value={email}
                        placeholder="juangonzalez@gmail.com"
                        type="email"
                        onChange={(event) => {
                            setEmail(event.target.value)
                            setEmailError('')
                        }}
                    />
                    <FormHelperText className="text-sm font-text !text-error">{emailError}</FormHelperText>
                </Box>
                
                <p className='font-text text-sm text-slate-400'>
                    Al hacer clic en "Enviar información" acepto los Términos y Condiciones generales de la política de privacidad de nowy.mx
                </p>
                
                <Box className="p-2 flex justify-center">
                    <Button
                        variant="contained"
                        className="w-48 rounded-full mt-[20px]"
                        onClick={() => {
                            evaluateContact()
                        }}
                        sx={{
                            "border-radius": '20px',
                            backgroundColor: "#63C088",
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#000',
                            },
                        }}
                    >
                        Enviar Informacion
                    </Button>
                </Box>
            </Box>
        </>)
    }

    const getRefundPrice = (price) =>{
        const numberPrice = parseFloat(price.replace(/,/g, ''));
        const priceValue = numberPrice + 10000;
        return priceValue.toLocaleString('en-US');
    }

    const renderQuotation = () => {
        if (step !== 'quotation' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader(false, true, true)}

            <Box className='grid place-items-center mb-[60px]'>
                <p className='font-title text-2xl text-brandPrimary my-[10px]'>
                    Oferta
                </p>

                <p className='font-text text-md'>
                    Avaluó sujeto a inspección mecánica
                </p>
            </Box>

            <Box className='grid place-items-center mb-[60px]'>
                <p className='font-title text-2xl mb-[10px]'>
                    {`${brand?.name || ''} ${model?.name || ''} ${year || ''}`}
                </p>
                
                <p className='font-title text-md text-brandPrimary'>
                    {`Avalúo de ${version?.Nombre || ''}`}
                </p>
            </Box>

            <Box className='grid place-items-center mb-[60px]'>
                <p className='font-title text-5xl md:text-6xl text-brandPrimary mb-[10px]'>
                    ${price}
                </p>

                <p className='font-title text-md'>
                    ${calculateValuation(price)} Valuación en el mercado
                </p>
            </Box>

            <Box className='grid place-items-center mb-[40px]'>
                <p className='font-title text-md'>
                    Compra un Seminuevo con nosotros y reciba
                </p>

                <p className='font-title text-5xl md:text-6xl text-brandPrimary mb-[10px]'>
                    ${getRefundPrice(price)}
                </p>

            </Box>
            <Box className='grid place-items-center mb-[40px]'>
                <p className='font-text text-md text-center text-slate-400'>
                Su vehículo debe ser entregado con papeles completos y sin adeudos. En 24 horas se realizará depósito posterior a una inspección mecánica, estética y administrativa. No realizamos compra de vehículos dañados. Todos los vehículos que adquiridos por compra en Nowy están sujetos a efectos fiscales y las regulaciones de las leyes mexicanas. Solicita más información o dudas de avalúo en atencion@nowy.mx  o llamando al 6693830570.
                </p>
            </Box>

            <Box className='grid place-items-center mb-[40px]'>
                <p className='font-text text-md text-center'>
                El Avaluó Digital es una referencia y está sujeto a cambios sin previo aviso. Válido por 8 Días Naturales
                </p>
            </Box>


            <Box className="p-2 flex justify-center">
                    <Button
                            variant="contained"
                            className="w-48 rounded-full"
                            onClick={() => {
                                setStep('appointment')
                                navigate(`agendar`)
                            }}
                            sx={{
                                "border-radius": '20px',
                                backgroundColor: "#63C088",
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: '#000',
                                },
                            }}
                        >
                            Agendar Inspeccion
                        </Button>
            </Box>
        </>)
    }

    const renderAppointment = () => {
        if (step !== 'appointment' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader(true, true, true)}

            <Box className='grid place-items-center mb-[40px]'>
                <p className='font-title text-2xl text-brandPrimary my-[10px]'>
                    Agende cita con los expertos
                </p>

                <p className='font-text text-md text-center'>
                    Para la valuación física de su vehículo, es necesario nos comparta sus datos de contacto
                </p>
            </Box>

            <Box className='mb-[20px]'>
                <p className='font-subtitle text-lg my-[10px]'>
                    Dirección personal
                </p>

                <Box className="p-2" id='state-selector'>
                    <Select
                        fullWidth
                        className="px-4 py-2 rounded-full border"
                        value={stateLabel}
                        onChange={(event) => {
                            for (const stateItem of states) {
                                if (stateItem.state === event.target.value) {
                                    setState(stateItem)
                                }
                            }

                            setStateLabel(event.target.value)
                            setStateError('')
                        }}
                    >
                        <MenuItem disabled value=''>
                            <em>Estado</em>
                        </MenuItem>
                        {states.map((stateItem, index) => (
                            <MenuItem key={`state_${index}`}
                                value={stateItem.state}
                            >
                                {stateItem.state}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText className="text-sm font-text !text-error">{stateError}</FormHelperText>
                </Box>
                
                <Box className="p-2" id='city-selector'>
                    <Select
                        fullWidth
                        className="px-4 py-2 rounded-full border"
                        value={cityLabel}
                        onChange={(event) => {
                            for (const cityItem of cities) {
                                if (cityItem.municipalite === event.target.value) {
                                    setCity(cityItem)
                                }
                            }

                            setCityLabel(event.target.value)
                            setCityError('')
                        }}
                    >
                        <MenuItem disabled value=''>
                            <em>Ciudad</em>
                        </MenuItem>
                        {cities.map((cityItem, index) => (
                            <MenuItem key={`city_${index}`}
                                value={cityItem.municipalite}
                            >
                                {cityItem.municipalite}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText className="text-sm font-text !text-error">{cityError}</FormHelperText>
                </Box>

                <Box className="p-2">
                    <Input
                        fullWidth
                        disableUnderline
                        className="px-4 py-2 rounded-full border"
                        value={postalCode}
                        placeholder="Código Postal"
                        type="number"
                        onChange={(event) => {
                            setPostalCode(event.target.value)
                            setPostalCodeError('')
                        }}
                    />
                    <FormHelperText className="text-sm font-text !text-error">{postalCodeError}</FormHelperText>
                </Box>
                
                <Box className="p-2">
                    <Input
                        fullWidth
                        disableUnderline
                        className="px-4 py-2 rounded-full border"
                        value={address}
                        placeholder="Dirección"
                        type="text"
                        onChange={(event) => {
                            setAddress(event.target.value)
                            setAddressError('')
                        }}
                    />
                    <FormHelperText className="text-sm font-text !text-error">{addressError}</FormHelperText>
                </Box>
            </Box>

            <Box className='mb-[20px]'>
                <p className='font-subtitle text-lg my-[10px]'>
                    Seleccione fecha y hora
                </p>

                <Box className="p-2" id="date-selector">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            className='w-full'
                            label='Fecha'
                            format='YYYY-MM-DD'
                            minDate={moment()}
                            value={date}
                            onChange={(value) => {
                                setDate(value)
                                setDateError('')
                            }}
                        />
                    </LocalizationProvider>
                    <FormHelperText className="text-sm font-text !text-error">{dateError}</FormHelperText>
                </Box>
                
                <Box className="p-2" id='hour-selector'>
                    <Select
                        fullWidth
                        className="px-4 py-2 rounded-full border"
                        value={hour}
                        onChange={(event) => {
                            setHour(event.target.value)
                            setHourError('')
                        }}
                    >
                        <MenuItem disabled value=''>
                            <em>Hora</em>
                        </MenuItem>
                        {hoursArray.map((hour, index) => (
                            <MenuItem key={`hour_${index}`}
                                value={hour}
                            >
                                {hour}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText className="text-sm font-text !text-error">{hourError}</FormHelperText>
                </Box>
            </Box>
            
            <Box className="p-2 flex justify-center">

                <Button
                    variant="contained"
                    className="w-48 rounded-full"
                    onClick={() => {
                        evaluateAppointment()
                    }}
                    sx={{
                        "border-radius": '20px',
                        backgroundColor: "#63C088",
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#000',
                        },
                    }}
                >
                    Confirmar Cita
                </Button>
            </Box>
        </>)
    }

    const renderDetail = () => {
        if (step !== 'detail' || loading !== null) {
            return null
        }

        return(<>
            {stepHeader(false, true, false)}

            <Box className='grid place-items-center mb-[20px]'>
                <CheckCircle sx={{
                    fontSize: 60,
                    color: '#00ce7c',
                }} />

                <p className='font-title text-2xl text-brandPrimary my-[10px]'>
                    Cita agendada
                </p>

                <p className='font-title text-md'>
                    Detalles enviados:
                </p>
            </Box>
            
            <Box className='flex items-center'>
                <Box className='mr-[10px]'>
                    <EventNote sx={{
                        fontSize: 30,
                        color: '#00ce7c',
                    }} />
                </Box>

                <p className='font-subtitle text-md'>
                    Fecha: &nbsp;
                </p>

                <p className='font-text text-md'>
                    {date.format('YYYY-MM-DD')}
                </p>
            </Box>

            <Box className='flex items-center'>
                <Box className='mr-[10px]'>
                    <AccessTime sx={{
                        fontSize: 30,
                        color: '#00ce7c',
                    }} />
                </Box>

                <p className='font-subtitle text-md'>
                    Hora: &nbsp;
                </p>

                <p className='font-text text-md'>
                    {hour}
                </p>
            </Box>

            <Box className='flex items-center'>
                <Box className='mr-[10px]'>
                    <LocationOn sx={{
                        fontSize: 30,
                        color: '#00ce7c',
                    }} />
                </Box>
                
                <p className='font-subtitle text-md'>
                    Dirección: &nbsp;
                </p>
                
                <p className='font-text text-md'>
                    {state.state}, {city.municipalite}, {address}
                </p>
            </Box>

            <Box className='flex items-center'>
                <Box className='mr-[10px]'>
                    <DirectionsCar sx={{
                        fontSize: 30,
                        color: '#00ce7c',
                    }} />
                </Box>
                
                <p className='font-subtitle text-md'>
                    Mi auto: &nbsp;
                </p>
                
                <p className='font-text text-md'>
                    {brand.name}, {model.name}, {year} ({version.Clave})
                </p>
            </Box>
            
            <Box className='flex items-center'>
                <Box className='mr-[10px]'>
                    <AlternateEmail sx={{
                        fontSize: 30,
                        color: '#00ce7c',
                    }} />
                </Box>
                
                <p className='font-subtitle text-md'>
                    Email: &nbsp;
                </p>
                
                <p className='font-text text-md'>
                    {email}
                </p>
            </Box>

            <Box className='flex items-center mb-[20px]'>
                <Box className='mr-[10px]'>
                    <LocalPhone sx={{
                        fontSize: 30,
                        color: '#00ce7c',
                    }} />
                </Box>
                
                <p className='font-subtitle text-md'>
                    Celular: &nbsp;
                </p>
                
                <p className='font-text text-md'>
                    {cellphone}
                </p>
            </Box>

            <Box className='grid place-items-center mb-[20px]'>
                <p className='font-text text-md'>
                    Usted es muy importante para nosotros en breve nuestro teleasesor se pondrá en contacto o bien si lo desea puede iniciar una conversación AHORA MISMO con nuestro centro de atención a clientes o llamar al movil 6693830570.
                </p>
            </Box>
            
            <Box className="flex justify-center py-4">
                <SocialIcon
                    url={buildWhatsappData()}
                    network="whatsapp"
                    fgColor="#fff"
                    style={{
                        height: 60,
                        width: 60,
                    }}
                    target="_blank"
                />

                <a href={zoomUrl} className="ml-4" target="_blank" rel="noreferrer">
                    <img src={zoom} width='60' alt='zoom' />
                </a>
            </Box>
            
            <Box className="flex justify-center py-4">
                <Button
                    variant="contained"
                    className="h-full w-full btn-indigo"
                    onClick={() => {
                        window.open('https://zoom.us/download')
                    }}
                >
                    No tienes software de zoom, descarguelo aquí
                </Button>
            </Box>
        </>)
    }

    // render component
    return <>
        <Modal
            open={showForm}
            onClose={() => {
                setShowForm(false)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='w-screen h-screen grid place-content-center'>
                <Box className='bg-white p-[40px] rounded-lg overflow-y-auto
                    w-screen lg:w-auto lg:min-w-[600px] lg:max-w-[800px]
                    h-screen lg:h-auto lg:min-h-[600px] lg:max-h-[800px]'
                >
                    {/* <SellCarYears years={years} /> */}
                    {renderYears()}
                    {renderBrands()}
                    {renderModels()}
                    {renderVersions()}
                    {renderColors()}
                    {renderKms()}
                    {renderContact()}
                    {renderQuotation()}
                    {renderAppointment()}
                    {renderDetail()}
                </Box>
            </Box>
        </Modal>
    </>
}

export default SellCarForm
