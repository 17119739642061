// react
import { useEffect, useRef, useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    Button,
    Chip,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Skeleton,
    Slider,
    TextField,
} from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import { Box } from '@mui/system'


// helpers
import catalogsHelper from '../../helpers/catalogs'

// services
import catalogsService from '../../services/catalogsService'
import searchService from '../../services/searchService'
import CarCard from './CarCard/CarCard'
import { Seo } from '../shared/Seo/Seo'

import { PaginationRounded } from './CarCard/PaginationRounded'

// private vars
const { sortCatalog } = catalogsHelper
const numberFormat = (number) => number.toLocaleString('es-MX')
const currentYear = +(new Date().getFullYear())
const yearsOptions = [...Array((currentYear + 10) - currentYear + 1).keys()].map(x => currentYear - x)
const minPriceFilter = 100000
const stepPriceFilter = minPriceFilter / 2
const maxPriceFilter = 750000
const marksPrice = [
    {
        value: 100000,
        label: '$100,000',
    },
    {
        value: 750000,
        label: '$750,000',
    },
]
const typeOptions = [
    {
        id: 1,
        name: 'Sedan',
    },
    {
        id: 2,
        name: 'SUV/Familiar',
    },
    {
        id: 3,
        name: 'Sport',
    },
    {
        id: 4,
        name: 'Comercial',
    },
    {
        id: 5,
        name: 'SUV Sport',
    },
    {
        id: 6,
        name: 'Premium',
    },
    {
        id: 7,
        name: 'Off Road',
    },
    {
        id: 8,
        name: 'Pickup',
    },
    {
        id: 9,
        name: 'Hatchback',
    },
    {
        id: 10,
        name: 'Passangers',
    },
]
const stateOptions = [
    {
        id: 1,
        name: 'Nuevo',
    },
    {
        id: 2,
        name: 'Seminuevo',
    },

];

// component
function CarsForSale() {
    // router
    const [searchParams, setSearchParams] = useSearchParams({})
    const navigate = useNavigate()

    // refs
    const dataFetchedRef = useRef(false)

    // state
    const [cars, setCars] = useState([])
    const [productPerpege] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [shownFilters, setShownFilters] = useState(false)
    const [loading, setLoading] = useState(true)
    const [filterTags, setFilterTags] = useState({})
    const [brandsOptions, setBrandsOptions] = useState([])
    const [brandsFilter, setBrandsFilter] = useState([])
    const [modelsOptions, setModelsOptions] = useState([])
    const [modelsFilter, setModelsFilter] = useState([])
    const [priceFilter, setPriceFilter] = useState([minPriceFilter, maxPriceFilter])

    useEffect(() => {
        setCurrentPage(1);
    }, [filterTags, brandsFilter, modelsFilter, priceFilter]);

    const totalProduct = cars.length;

    const indexOfLastProduct = currentPage * productPerpege;
    const indexOfFirstProduct = indexOfLastProduct - productPerpege;
    const currentCars = cars.slice(indexOfFirstProduct, indexOfLastProduct);


    // callbacks
    const getCarsParams = useCallback(() => {
        setLoading(true)

        const fetchData = () => {
            catalogsService.brands().then(brands => {
                setBrandsOptions(sortCatalog(brands))
                setBrandsFilter(brands)
            })
        }

        if (!dataFetchedRef.current) {
            dataFetchedRef.current = true
            fetchData()
        }

        const query = searchParams.entries()
        let filter = ''
        let tags = {}
        let brandValue = null
        let modelValue = null

        for (const [key, value] of query) {
            switch (key) {
                case 'marca':
                    filter += `${filter === '' ? '' : '&'}brandId=${value}`
                    brandValue = value
                    break

                case 'modelo':
                    filter += `${filter === '' ? '' : '&'}modelId=${value}`
                    modelValue = value
                    break

                case 'tipo':
                    for (const type of typeOptions) {
                        if (type.id === Number(value) || type.name === value) {
                            filter += `${filter === '' ? '' : '&'}typeId=${type.id}`
                            tags.tipo = type.name
                        }
                    }
                    break

                case 'año':
                    filter += `${filter === '' ? '' : '&'}year=${value}`
                    tags['año'] = value
                    break

                case 'estado':
                    const kmThreshold = 1000;
                    const estadoFilter = value === 'Nuevo' ? `<${kmThreshold}` : `>=${kmThreshold}`;
                    filter += `${filter === '' ? '' : '&'}km=${estadoFilter}`;
                    tags.estado = value;
                    break;

                case 'precio':
                    const priceRange = value.split('-')
                    const minPrice = priceRange[0]
                    const maxPrice = priceRange[1]

                    filter += `${filter === '' ? '' : '&'}pricemin=${minPrice}&&pricemax=${maxPrice}`
                    tags.precio = `$${numberFormat(minPrice)} - $${numberFormat(maxPrice)}`
                    setPriceFilter([minPrice, maxPrice])
                    break

                default: { }
            }
        }

        if (brandValue) {
            catalogsService.brands().then(brands => {
                brands = sortCatalog(brands)

                for (const brand of brands) {
                    if (brand.id === Number(brandValue) || brand.name === brandValue) {
                        filter += `${filter === '' ? '' : '&'}brandId=${brand.id}`
                        tags.marca = brand.name

                        // reset models
                        setModelsOptions([])
                        setBrandsFilter(brands)

                        // search models for brand
                        // eslint-disable-next-line
                        catalogsService.models(brand.id).then(models => {
                            models = sortCatalog(models)

                            setModelsOptions(models)
                            setModelsFilter(models)

                            if (modelValue) {
                                for (const model of models) {
                                    if (model.id === Number(modelValue) || model.name === modelValue) {
                                        filter += `${filter === '' ? '' : '&'}modelId=${model.id}`
                                        tags.modelo = model.name

                                        setFilterTags(tags)
                                        getCars(filter)
                                        return
                                    }
                                }
                            }

                            setFilterTags(tags)
                            getCars(filter)
                            return
                        })
                    }
                }

                setFilterTags(tags)
                getCars(filter)
            })
            return
        }

        setFilterTags(tags)
        getCars(filter)
    }, [searchParams])

    // effects
    useEffect(() => {
        getCarsParams()
    }, [getCarsParams])

    // functions
    function getCars(filters = null) {
        searchService.search(filters ? filters : '').then(search => {
            let cars = search.data ? search.data : search
            setCars(!!cars.length ? cars : Object.values(cars))
            setLoading(false)
        })
    }

    function selectBrand(brand) {
        closeFilters()
        setLoading(true)
        setModelsOptions([])
        setBrandsFilter(brandsOptions)
        catalogsService.models(brand.id).then(models => {
            setModelsOptions(sortCatalog(models))
            setModelsFilter(sortCatalog(models))
        })

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        if (search.modelo) {
            delete search.modelo
        }

        setSearchParams({
            ...search,
            marca: brand.name,
        })
    }

    function selectModel(model) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            modelo: model.name,
        })
    }

    function selectType(type) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            tipo: type.name,
        })
    }

    function applyPriceFilter() {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            precio: `${priceFilter[0]}-${priceFilter[1]}`,
        })
    }

    function selectState(state) {
        closeFilters();
        setLoading(true);

        const search = {};
        const query = searchParams.entries();
        for (const [key, value] of query) {
            search[key] = value;
        }

        setSearchParams({
            ...search,
            estado: state.name,
        });
    }

    const closeFilters = () => {
        setShownFilters(false)
    }

    const goToCar = (car) => {
        navigate(`/auto/${prettyUrl(car.model.name)}/${car.year}/${car.id}`)
    }

    const prettyUrl = (value) => {
        return value.replace(/ /g, "-")
            .replace(/@/g, "")
            .replace(/\$/g, "")
            .replace(/!/g, "")
            .replace(/#/g, "")
            .toLowerCase()
    }

    const toggleFilters = () => {
        setShownFilters(!shownFilters)
    }

    const deleteFilter = (removeKey) => {
        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            if (key !== removeKey) {
                search[key] = value
            }
        }

        setLoading(true)
        setSearchParams(search)
    }

    const handleBrandFilterChange = (e) => {
        const search = e.target.value
        setBrandsFilter(brandsOptions.filter(brand => brand.name.toLowerCase().includes(search.toLowerCase())))
    }

    const handleModelFilterChange = (e) => {
        const search = e.target.value
        setModelsFilter(modelsOptions.filter(model => model.name.toLowerCase().includes(search.toLowerCase())))
    }

    function selectYear(year) {
        closeFilters()
        setLoading(true)

        const search = {}
        const query = searchParams.entries()
        for (const [key, value] of query) {
            search[key] = value
        }

        setSearchParams({
            ...search,
            año: year,
        })
    }

    const minPriceDistance = 10000

    const handlePriceFilterChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return
        }

        if (activeThumb === 0) {
            setPriceFilter([Math.min(newValue[0], priceFilter[1] - minPriceDistance), priceFilter[1]])
        } else {
            setPriceFilter([priceFilter[0], Math.max(newValue[1], priceFilter[0] + minPriceDistance)])
        }
    }

    const valuePrice = (value) => {
        return `$${numberFormat(value)}`
    }

    return <>
        <Seo
            title="Nowy | Compra Autos Seminuevos de agencia"
            description="Encuentra los mejores autos seminuevos de agencia. Compra autos con garantía y a precios atractivos. Explora nuestro catálogo de vehículos disponibles."
            companyName="Nowy"
            type="website"
        />
        <div className="container mx-auto my-8 px-8 md:px-0 min-h-[100vh]">
            {!loading && (
                <div className="py-4 w-full lg:flex">
                    <span className="cursor-pointer mr-8 mt-1" onClick={toggleFilters}>
                        <TuneIcon /> {shownFilters ? 'Ocultar' : 'Mostrar'} filtros
                    </span>
                    {Object.keys(filterTags).map((key, index) => (<Chip key={`chip_filter_${index}`}
                        sx={{
                            marginRight: '10px',
                            '& .MuiChip-deleteIcon': {
                                color: 'white',
                            },
                        }}
                        variant="outlined"
                        label={filterTags[key]}
                        style={{ backgroundColor: 'black', color: 'white' }}
                        onDelete={() => {
                            deleteFilter(key)
                        }}
                    />))}

                    <div className="ml-auto sm:text-center">
                        {cars.length > 0 && (
                            <p className="text-gray-600">{cars.length} vehículos disponibles</p>
                        )}
                    </div>

                </div>
            )}

            <div className="flex flex-row">
                {shownFilters && (
                    <div className="filters__content w-full z-50 py-8 md:py-0 md:w-1/5 h-full overflow-auto md:h-auto px-6 md:px-0 md:mr-8 fixed top-0 left-0 md:relative bg-white">
                        <div className="pb-4 flex md:hidden justify-between">
                            <h2>Filtros</h2>
                            <span onClick={closeFilters}>Cerrar</span>
                        </div>

                        <div className="pb-4 border-b border-slate-300">
                            <div className="pb-3">
                                <b >Marca</b>
                            </div>
                            <div>
                                <TextField sx={{ width: '100%' }} variant="filled" onChange={handleBrandFilterChange} />
                            </div>
                            <List className="w-full"
                                sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                            >
                                {brandsFilter.map((brand, index) => (
                                    <ListItem key={`brand-${index}`} disablePadding>
                                        <ListItemButton selected={filterTags.marca === brand.name}
                                            onClick={() => selectBrand(brand)}
                                        >
                                            <ListItemText primary={brand.name} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>

                        {filterTags.marca && !!modelsOptions.length && (
                            <div className="pb-4 border-b border-slate-300 mt-8">
                                <div className="pb-3">
                                    <b>Modelo</b>
                                </div>
                                <div>
                                    <TextField sx={{ width: '100%' }} variant="filled" onChange={handleModelFilterChange} />
                                </div>
                                <List className="w-full"
                                    sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                                >
                                    {modelsFilter.map((model, index) => (
                                        <ListItem key={`model-${index}`} disablePadding>
                                            <ListItemButton selected={filterTags.modelo === model.name}
                                                onClick={() => selectModel(model)}
                                            >
                                                <ListItemText primary={model.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        )}

                        <div className="pb-4 border-b border-slate-300 mt-8">
                            <div className="pb-3">
                                <b>Año</b>
                            </div>
                            <div className="flex flex-wrap items-stretch">
                                {yearsOptions.map((year, index) => (
                                    <div className="mr-1 my-2" key={`year-${index}`}>
                                        <Chip
                                            label={year}
                                            variant={Number(filterTags['año']) === year ? 'filled' : 'outlined'}
                                            onClick={() => selectYear(year)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="pb-4 border-b border-slate-300 mt-8">
                            <div className="pb-3">
                                <b>Tipo de Vehículo</b>
                            </div>
                            <List className="w-full"
                                sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                            >
                                {typeOptions.map((type, index) => (
                                    <ListItem key={`type-${index}`} disablePadding>
                                        <ListItemButton selected={filterTags.tipo === type.name}
                                            onClick={() => selectType(type)}
                                        >
                                            <ListItemText primary={type.name} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>

                        <div className="pb-4 border-b border-slate-300 mt-8">
                            <div className="pb-3">
                                <b>Estado del Vehículo</b>
                            </div>
                            <List className="w-full"
                                sx={{ overflowY: 'auto', maxHeight: '300px', bgcolor: 'background.paper' }}
                            >
                                {stateOptions.map((state, index) => (
                                    <ListItem key={`state-${index}`} disablePadding>
                                        <ListItemButton selected={filterTags.estado === state.name}
                                            onClick={() => selectState(state)}
                                        >
                                            <ListItemText primary={state.name} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>

                        <div className="pb-4 border-b border-slate-300 mt-8">
                            <div className="pb-3">
                                <b>Precio</b>
                            </div>
                            <div className="text-center">
                                <Box sx={{ width: '80%', margin: 'auto' }}>
                                    <Slider
                                        getAriaLabel={() => 'Minimum distance'}
                                        marks={marksPrice}
                                        value={priceFilter}
                                        min={minPriceFilter}
                                        max={maxPriceFilter}
                                        step={stepPriceFilter}
                                        onChange={handlePriceFilterChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuePrice}
                                        valueLabelFormat={valuePrice}
                                        disableSwap
                                    />
                                </Box>
                                <Button variant="outlined" onClick={applyPriceFilter}>Aplicar precio</Button>
                            </div>
                        </div>

                    </div>
                )}



                <div className="w-4/5 grow md:px-0">
                    <Grid container spacing={2} sx={{ display: 'grid', gridTemplateColumns: ' repeat(auto-fill, minmax(320px, 1fr))' }}>
                        {loading && Array.from(new Array(8)).map((item, index) => (
                            <Grid item key={`loading-${index}`}>
                                <Box key={index} sx={{ marginRight: 0.5, mb: 2 }} className="w-full">
                                    <Skeleton variant="rectangular" height={250} />
                                    <h2 className="mt-2">
                                        <Skeleton variant="rectangular" height={20} />
                                    </h2>
                                </Box>
                            </Grid>
                        ))}

                        {!loading && cars.length === 0 && (<div className="text-center py-8">
                            <b>Sin resultados</b>
                        </div>)}

                        {!loading && (currentCars.map((car, index) => (
                            <Grid
                                key={`car-${index}`}
                                item
                                // xs={12}
                                // sm={6}
                                // md={4}
                                // xl={3}
                                onClick={() => goToCar(car)}
                            >
                                <CarCard car={car} />
                            </Grid>
                        ))
                        )}

                    </Grid>
                    {cars.length > 0 && (
                        <div className="flex justify-center mt-4">
                            <PaginationRounded
                                productPerpege={productPerpege}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalProduct={totalProduct}
                            />
                        </div>

                    )}
                </div>

            </div>

        </div>
    </>
}

// export component
export default CarsForSale
